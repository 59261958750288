import {
    AppstoreAddOutlined,
    DownloadOutlined,
    SaveOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    TimePicker,
    Tooltip,
    Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { paginationParams } from "utils/constants";
import groupApi from "../group-user/api";
import { COLUMNS } from "./columns2";
import Actions from "./components/Action";
import ConfigParaModal from "./components/ConfigParamModal";
import * as playlistAction from "./slice";
import ImportModal from "./components/ImportModal";
import ExportModal from "./components/ExportModal";

const { Option } = Select;
const { TextArea } = Input;

const inputNode = (inputType, inputProps) => {
    switch (inputType) {
        case "number":
            return <InputNumber {...inputProps} />;
        case "text":
            return <Input {...inputProps} />;
        case "textarea":
            return (
                <TextArea
                    autoSize={{
                        minRows: 1,
                        maxRows: 5,
                    }}
                    {...inputProps}
                />
            );
        case "switch":
            return <Switch {...inputProps} />;
        case "date":
            return <DatePicker format={"DD/MM/YYYY"} {...inputProps} />;
        case "time":
            return <TimePicker format={"HH:mm"} {...inputProps} />;
        case "datetime":
            return (
                <DatePicker
                    format={"DD/MM/YYYY HH:mm"}
                    showTime
                    {...inputProps}
                />
            );
        case "select":
            return (
                <Select>
                    {inputProps?.options?.map((e, i) => (
                        <Option key={i} value={e.value}>
                            {e.label}
                        </Option>
                    ))}
                </Select>
            );
        case "select_multiple":
            return (
                <Select mode="multiple" allowClear>
                    {inputProps?.options?.map((e, i) => (
                        <Option key={i} value={e.value}>
                            {e.label}
                        </Option>
                    ))}
                </Select>
            );
        default:
            return <></>;
    }
};

const EditableCell = (props) => {
    const {
        editing,
        dataIndex,
        name,
        title,
        inputType,
        inputProps,
        record,
        index,
        children,
        ...restProps
    } = props;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={name}
                    valuePropName={inputType === "switch" ? "checked" : "value"}
                    style={{ margin: 0 }}
                >
                    {inputNode(inputType, inputProps)}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Playlist = () => {
    const dispatch = useDispatch();
    const {
        playlists,
        listUrl,
        pagination,
        isLoading,
        isProcessing,
        isCompleted,
    } = useSelector((state) => state.playlist);

    const [form] = Form.useForm();

    const [editingKey, setEditingKey] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [openConfigParamModal, setOpenConfigParamModal] = useState(false);
    const [columns, setColumns] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [openExportModal, setOpenExportModal] = useState(false);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);

    const actionColumn = {
        title: "Thao tác",
        align: "center",
        fixed: "right",
        width: "150px",
        render: (record) => {
            const editable = record.id === editingKey;
            return editable ? (
                <Space size="small">
                    <Popconfirm
                        title="Sure to save?"
                        onConfirm={() => save(record.id)}
                    >
                        <Button type="primary" icon={<SaveOutlined />}>
                            Lưu
                        </Button>
                    </Popconfirm>
                    <a onClick={onCancelEdit}>Hủy</a>
                </Space>
            ) : (
                <Actions record={record} onEdit={onEdit} onDelete={onDelete} />
            );
        },
    };

    useEffect(() => {
        dispatch(playlistAction.fetchPlaylists(pagination));
        const fetchGroupOptions = async () => {
            try {
                const groups = await groupApi.getList();
                if (groups.error) {
                    toast.error(groups.error);
                } else {
                    const _groups = groups.data.map((e) => ({
                        value: e.id,
                        label: e.name,
                    }));
                    setGroupOptions(_groups);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };
        fetchGroupOptions();
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenImportModal(false);
            setOpenExportModal(false);
            if (editingKey) onCancelEdit();
        }
    }, [isCompleted]);

    useEffect(() => {
        const showParams =
            localStorage.getItem("playlist_show_params")?.split(",") ||
            COLUMNS.map((e) => e.cKey);
        setColumns([
            ...COLUMNS.filter((param) => showParams.includes(param.cKey)),
            actionColumn,
        ]);
    }, [editingKey]);

    const onOpenExportModal = () => {
        setOpenExportModal(true);
    };

    const onCloseExportModal = () => {
        setOpenExportModal(false);
        // dispatch(proxyAction.setIsProcessing(false));
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        // dispatch(proxyAction.setIsProcessing(false));
    };

    const onImport = (dataImport) => {
        console.log(dataImport);
        dispatch(playlistAction.importPlaylist(dataImport));
    };

    const onOpenConfigParamModal = () => {
        setOpenConfigParamModal(true);
    };

    const onCloseConfigParamModal = () => {
        setOpenConfigParamModal(false);
    };

    const onSaveConfigParam = (showParams) => {
        localStorage.setItem("playlist_show_params", showParams.join(","));
        setColumns([
            ...COLUMNS.filter((param) => showParams.includes(param.cKey)),
            actionColumn,
        ]);
        setOpenConfigParamModal(false);
    };

    const onTableChange = (pagination) => {
        dispatch(
            playlistAction.fetchPlaylists({
                ...pagination,
                key: searchName,
            })
        );
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSearch = (searchValue) => {
        setSearchName(searchValue);
        dispatch(
            playlistAction.fetchPlaylists({
                ...pagination,
                key: searchValue,
            })
        );
    };

    const onDelete = (playlist) => {
        Modal.confirm({
            title: `Xác nhận xóa playlist`,
            content: `Bạn có chắc chắn muốn xóa playlist này?`,
            onOk: () => dispatch(playlistAction.deletePlaylist(playlist.id)),
            centered: true,
        });
    };

    const onDeleteMany = () => {
        const selectedPlaylists = selectedRows.map((e) => e.id);
        Modal.confirm({
            title: `Xác nhận xóa playlist`,
            content: `Bạn có chắc chắn muốn xóa ${selectedPlaylists.length} playlist đã chọn?`,
            onOk: () =>
                dispatch(playlistAction.deletePlaylists(selectedPlaylists)),
            centered: true,
        });
    };

    const onEdit = (record) => {
        const data = { ...record };
        form.setFieldsValue({
            keyword: "",
            name: "",
            age: "",
            address: "",
            ...data,
            enable: !!data.enable,
            start_time: data.start_time ? dayjs(data.start_time) : null,
            stop_time: data.stop_time ? dayjs(data.stop_time) : null,
        });
        setEditingKey(record.id);
    };

    const onCancelEdit = () => {
        setEditingKey("");
    };

    const onExport = (fields) => {
        dispatch(playlistAction.exportPlaylist(fields));
    };

    const save = async (key) => {
        const editData = await form.validateFields();
        if (editData.start_time)
            editData.start_time = editData.start_time
                .second(0)
                .format("YYYY-MM-DD HH:mm:ss");
        if (editData.stop_time)
            editData.stop_time = editData.stop_time
                .second(0)
                .format("YYYY-MM-DD HH:mm:ss");
        editData.enable = editData.enable ? 1 : 0;
        const recordData = playlists.find((e) => e.id === key);

        // Hàm so sánh sâu 
        const isEqual = (a, b) => {
            if (Array.isArray(a) && Array.isArray(b)) {
                return (
                    a.length === b.length &&
                    a.every((val, index) => val === b[index])
                );
            }
            return a === b;
        };

        // Chỉ lấy các trường trong editData mà giá trị thay đổi
        const updateData = {};
        Object.keys(editData).forEach((field) => {
            if (!isEqual(editData[field], recordData[field])) {
                updateData[field] = editData[field];
            }
        });

        if (Object.keys(updateData).length > 0) {
            dispatch(playlistAction.updatePlaylist(key, updateData));
        } else {
            toast("Không có gì thay đổi", { hideProgressBar: true });
        }
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) return col;

        return {
            ...col,
            onCell: (record) => {
                const props = {
                    record,
                    inputType: COLUMNS.find((e) => e.cKey === col.cKey)
                        .editType,
                    inputProps: COLUMNS.find((e) => e.cKey === col.cKey).props,
                    name:
                        COLUMNS.find((e) => e.cKey === col.cKey).name ||
                        col.dataIndex,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: record.id === editingKey,
                };
                if (col.cKey === "group_id") {
                    props.inputProps.options = groupOptions;
                }
                return props;
            },
        };
    });

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
            setEditingKey("");
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Space size="middle">
                    <Typography.Title level={2}>
                        QUẢN LÝ PLAYLIST
                    </Typography.Title>
                </Space>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm kiếm url hoặc title ..."
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import
                    </Button>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => onOpenExportModal()}
                    >
                        Export
                    </Button>
                    <Tooltip title="Thiết lập chỉ số hiển thị">
                        <Button
                            type="primary"
                            // shape="circle"
                            icon={<AppstoreAddOutlined />}
                            onClick={onOpenConfigParamModal}
                        />
                    </Tooltip>
                </Space>
            </Row>
            <br />
            {selectedRows.length ? (
                <Space size="middle" className="mb-4">
                    {/* <Button type="primary" icon={<UploadOutlined />}>
                        Export CSV
                    </Button> */}
                    <Button type="primary" danger onClick={onDeleteMany}>
                        Xóa tất cả
                    </Button>
                </Space>
            ) : null}
            <Form form={form} component={false}>
                <Table
                    scroll={{ x: "100%", y: "calc(100vh - 430px)" }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    sticky={{ offsetHeader: 65 }}
                    bordered
                    dataSource={playlists}
                    rowKey={(record) => record.url}
                    columns={mergedColumns}
                    rowSelection={rowSelection}
                    loading={isLoading}
                    rowClassName="editable-row"
                    pagination={{
                        ...paginationParams,
                        ...pagination,
                    }}
                    onChange={onTableChange}
                />
            </Form>

            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                    listUrl={listUrl}
                />
            )}

            {openExportModal && (
                <ExportModal
                    open={openExportModal}
                    isProcessing={isProcessing}
                    onSubmit={onExport}
                    onCancel={onCloseExportModal}
                    // data={dataExport}
                />
            )}

            {openConfigParamModal && (
                <ConfigParaModal
                    open={openConfigParamModal}
                    onCancel={onCloseConfigParamModal}
                    onSubmit={onSaveConfigParam}
                />
            )}
        </>
    );
};
export default Playlist;
