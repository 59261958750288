import {
    Card,
    Col,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Typography,
    DatePicker,
    Button,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AndroidOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { paginationParams } from "utils/constants";
import * as hostAction from "./slice";
import hostApi from "./api";
import moment from "moment/moment";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

function Host() {
    const dispatch = useDispatch();
    const { hosts, summary, pagination, isLoading, isLoadingSummary } =
        useSelector((state) => state.host);

    const [searchPlaylistId, setSearchPlaylistId] = useState("");

    const [lpl, setLpl] = useState([]);
    const [showCardDetail, setShowCardDetail] = useState(false);
    const [idd, setIdd] = useState(null);
    const [title, setTitle] = useState("Profiles");
    const [countEmail, setCountEmail] = useState(0);
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        dispatch(hostAction.fetchHostSummary());
        const fetchListFilter = async () => {
            try {
                const listPlaylist = await hostApi.getFilter();
                if (listPlaylist.error) {
                    toast.error(listPlaylist.error);
                } else {
                    const _listPlaylist = listPlaylist.data.playlists.map(
                        (e) => ({
                            value: e.id,
                            label: `${e.title} (${e.url_type})`,
                        })
                    );
                    setLpl(_listPlaylist);
                }
            } catch (err) {
                console.log(err);
                // toast.error(err.message);
            }
        };
        fetchListFilter();
    }, []);

    const onSelectSearchPlaylist = (option) => {
        if (option == undefined) {
            setShowCardDetail(false);
            setShowTable(false);
            setIdd(null);
        } else {
            setIdd(option.value);
            setSearchPlaylistId(option.value);
            setTitle(option.label);     
        }
    };

    useEffect(() => {
        if (idd || hosts.length > 0) {
            setCountEmail(pagination.total);
        }
    }, [hosts]);

    const filterOption = (input, option) => {
        return option.children.includes(input);
    };

    const onTableChange = async (pagination) => {
        dispatch(
            hostAction.fetchHosts({
                ...pagination,
                id: searchPlaylistId,
            })
        );
    };

    const onChange = (value) => {
        setDateRange(value);
    };

    const onClickSearch = () => {
        if (idd && dateRange) {
            setShowTable(true);
            setShowCardDetail(true);
            dispatch(
                hostAction.fetchHosts({
                    ...pagination,
                    id: idd,
                    dateFrom: dateRange[0].format('YYYY-MM-DD'),
                    dateTo: dateRange[1].format('YYYY-MM-DD'),
                })
            );
        } else {
            toast.warning("Vui lòng chọn đầy đủ thông tin để search!!");
        }
    };

    const columns = [
        {
            title: "Profile ID",
            dataIndex: "profile_id",
            width: "80px",
            fixed: "left",
        },
        {
            title: "VM ID",
            dataIndex: "vm_id",
            width: "65px",
        },
        {
            title: "Playlist ID",
            dataIndex: "channel_id",
            width: "80px",
        },
        {
            title: "URL",
            dataIndex: "url",
            width: "100px",
        },
        {
            title: "Người click",
            dataIndex: "note",
            width: "100px",
        },
        {
            title: "Ngày tạo",
            dataIndex: "create_time",
            align: "center",
            width: "100px",
            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "update_time",
            align: "center",
            width: "100px",
            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
    ];
    return (
        <>
            <Typography.Title level={2}>Report Click</Typography.Title>
            <br />
            <Row gutter={12} justify="start">
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Playlist Enable"
                            value={summary.total}
                            prefix={<AndroidOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                {showCardDetail && (
                    <>
                        <Col span={6}>
                            <Card>
                                <Statistic
                                    title={title}
                                    value={`${countEmail} reports`}
                                    prefix={<FolderOpenOutlined />}
                                />
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Tìm theo playlist"
                        onChange={onSelectSearchPlaylist}
                        filterOption={filterOption}
                        labelInValue
                    >
                        {lpl?.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <RangePicker
                        format="DD-MM-YYYY"
                        placeholder={["Start Time", "End Time"]}
                        onChange={onChange}
                        value={dateRange}
                    />
                    <Button type="primary" onClick={() => onClickSearch()}>
                        Search
                    </Button>
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            {showTable && (
                <Table
                    bordered
                    loading={isLoading}
                    columns={columns}
                    dataSource={hosts}
                    rowKey={(record) => record.id}
                    pagination={{
                        ...paginationParams,
                        ...pagination,
                    }}
                    scroll={{ x: 1500, y: "calc(100vh - 600px)" }}
                    onChange={onTableChange}
                />
            )}
        </>
    );
}

export default Host;
