import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/playlists";
        return axiosClient.get(url, { params });
    },
    getAllUrrl: () => {
        const url = "/api/playlists/all-url";
        return axiosClient.get(url);
    },
    update: (id, payload) => {
        const url = `/api/playlists/${id}`;
        return axiosClient.put(url, payload);
    },
    delete: (id) => {
        const url = `/api/playlists/${id}`;
        return axiosClient.delete(url);
    },
    deleteMany: (ids) => {
        const url = `/api/playlists/delete-many`;
        return axiosClient.post(url, ids);
    },
    import_: (payload) => {
        const url = `/api/playlists/import`;
        return axiosClient.post(url, payload);
    },
    export_: (payload) => {
        const url = `/api/playlists/export`;
        return axiosClient.post(url, payload);
    },
};
